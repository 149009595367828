<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>分类：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.module" placeholder="请选择" clearable>
                        <el-option
                                v-for="item in moduleList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>状态：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.status" placeholder="请选择" clearable>
                        <el-option
                                v-for="item in statusList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseTable" style="margin-top: 10px;" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         ref="publicPageUserStudent">
                <el-table-column prop="created_at" label="导入时间" width="110"></el-table-column>
                <el-table-column label="导入分类">
                    <template #default="scope">
                        {{moduleReturnName[scope.row.module]}}
                    </template>
                </el-table-column>
                <el-table-column prop="user.name" label="操作人员"></el-table-column>
                <el-table-column label="状态">
                    <template #default="scope">
                        {{statusReturnName[scope.row.status]}}
                    </template>
                </el-table-column>
                <el-table-column prop="success_number" label="成功数量">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary" @click.prevent="detailExport(scope.row,1)">
                            {{scope.row.success_number}}
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="fail_number" label="失败数量">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary" @click.prevent="detailExport(scope.row,0)">
                            {{scope.row.fail_number}}
                        </el-link>
                    </template>
                </el-table-column>
                <!--<el-table-column label="操作">-->
                <!--<template #default="scope">-->
                <!--&lt;!&ndash;<el-link class="tableButton" type="primary" @click.prevent="openDetail(scope.row)">&ndash;&gt;-->
                <!--&lt;!&ndash;查看失败文件&ndash;&gt;-->
                <!--&lt;!&ndash;</el-link>&ndash;&gt;-->
                <!--<el-link class="tableButton" type="primary" @click.prevent="detailExport(scope.row,1)">-->
                <!--下载成功文件-->
                <!--</el-link>-->
                <!--<el-link class="tableButton" type="primary" @click.prevent="detailExport(scope.row,2)">-->
                <!--下载失败文件-->
                <!--</el-link>-->
                <!--</template>-->
                <!--</el-table-column>-->
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-drawer title="失败列表" v-model="drawer" :close-on-click-modal="false" :destroy-on-close="true" size="80%">
            <div class="baseTable">
                <publicTable :publicTableOtherHeight="userDetailHeight + 30"
                             :publicTableData="detailTableData" ref="detailTableUserStudent">
                    <!--<template v-if="selMsg.module == 'payee'">-->
                    <!--<el-table-column prop="card_number" label="学工号"></el-table-column>-->
                    <!--<el-table-column prop="user.name" label="姓名"></el-table-column>-->
                    <!--<el-table-column prop="price" label="应缴金额"></el-table-column>-->
                    <!--</template>-->
                    <el-table-column prop="created_at" label="时间" width="110"></el-table-column>
                    <el-table-column prop="fail_reason" label="失败原因"></el-table-column>
                </publicTable>
            </div>
            <div class="basePage" style="margin-top: 4px;">
                <publicPage ref="detailPageUserStudent" :publicPageTotal="{total:detailTotal}"
                            @pageChange="getDetailTableData"></publicPage>
            </div>
        </el-drawer>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import XLSX from 'xlsx'

    import {businessGet} from '../../api/business.js'

    import {utilsExportExcel} from '../../utils/utils.js'

    export default {
        name: "systemImport",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                statusReturnName: {
                    '-1': '失败',
                    '0': '处理中',
                    '1': '处理完成',
                    '2': '导入中',
                    '3': '导入成功',
                },
                statusList: [
                    {
                        value: '-1',
                        label: '失败'
                    },
                    {
                        value: '0',
                        label: '处理中'
                    },
                    {
                        value: '1',
                        label: '处理完成'
                    },
                    {
                        value: '2',
                        label: '导入中'
                    },
                    {
                        value: '3',
                        label: '导入成功'
                    },
                ],
                moduleReturnName: {
                    'student': '学生',
                    'teacher': '老师',
                    'projects': '项目',
                    'payee': '收款用户',
                    'recruit_teacher': '招生老师'
                },
                moduleList: [
                    {
                        value: 'student',
                        label: '学生'
                    },
                    {
                        value: 'teacher',
                        label: '老师'
                    },
                    {
                        value: 'projects',
                        label: '项目'
                    },
                    {
                        value: 'payee',
                        label: '收款用户'
                    },
                    {
                        value: 'recruit_teacher',
                        label: '招生老师'
                    },
                ],
                searchForm: {
                    module: '',
                    status: '',
                },
                searchHeight: 0,
                tableLoading: false,
                tableData: [],
                total: 0,
                selMsg: null,
                drawer: false,
                userDetailHeight: 0,
                detailTableData: [],
                detailTotal: 0,
            }
        },
        created() {
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let searchHeight = baseSearchDom.offsetHeight + 10;
                this.searchHeight = searchHeight;
                console.log(this.$route.query);
                if (this.$route.query.module) {
                    this.searchForm.module = this.$route.query.module
                }
                this.search();
            })
        },
        methods: {
            detailExport(val, is_success) {
                let msg = '';
                if (is_success == 1) {
                    if (val.success_number > 0) {
                        msg = '此操作将下载成功的文件，是否继续'
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: '成功的数量为0，无需下载',
                            type: 'warning'
                        });
                        return
                    }
                }
                else if (is_success == 0) {
                    if (val.fail_number > 0) {
                        msg = '此操作将下载失败的文件，是否继续'
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: '失败的数量为0，无需下载',
                            type: 'warning'
                        });
                        return
                    }
                }
                let url = '/admin/v1/import/fail_log?is_success=' + is_success + '&is_export=1&module=' + val.module + '&batch_no=' + val.batch_no;
                utilsExportExcel(url, msg)
            },
            detailCleanAll() {
                this.detailSearch();
            },
            detailSearch() {
                this.$refs.detailPageUserStudent.currentPage = 1;
                this.getDetailTableData();
            },
            getDetailTableData() {
                let url = '/admin/v1/import/fail_log?module=' + this.selMsg.module + '&batch_no=' + this.selMsg.batch_no + '&page=' + this.$refs.detailPageUserStudent.currentPage + '&page_pre=' + this.$refs.detailPageUserStudent.pageSize;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.detailTotal = res.data.data.count;
                        let tableData = res.data.data.fail_log;
                        // tableData.forEach(item => {
                        //     item.statusName = this.statusObj[item.status];
                        //     item.typeName = this.typeObj[item.type]
                        // })
                        this.detailTableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openDetail(val) {
                this.selMsg = val;
                this.drawer = true;
                this.$nextTick(() => {
                    this.detailCleanAll()
                })
            },
            cleanAll() {
                this.searchForm.module = '';
                this.searchForm.status = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/import/log?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    url = url + '&' + k + '=' + this.searchForm[k];
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        this.tableData = res.data.data.import_log;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            exportExcel() {
                this.$confirm('此操作将导出数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {

                })
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .container {
        width: 100%;
        height: 600px;
        box-sizing: border-box;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

</style>
